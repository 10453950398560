import React from "react";
import styled from 'styled-components';
import { modularScale } from '../util/modularScale';
import NoLayout from '../components/layouts/NoLayout';

const Container = styled.div`
	display: flex;
	align-items: center;
    justify-content: center;
    flex-direction: column;
	width: 100%;
	max-width: 90%;
	margin: 0 auto;
	height: 100vh;
	
	p {
	    margin-bottom: 8rem;
	}
	
	h1 {
	    font-size: ${modularScale(10)};
	    line-height: 1;
	    margin: 0;
	}
`;

const Divider = styled.hr`
	border: 0;
	border-bottom: 1px solid black;
	width: 40px;
	margin: 2rem;
`;

const NotFoundPage = () => (
    <NoLayout>
        <Container>
            <h1>404</h1>
            <Divider />
            <p>The page you are looking for could not be found.</p>
        </Container>
    </NoLayout>
)

export default NotFoundPage
